import * as Sentry from '@sentry/sveltekit';
import { isDevelopment, isProduction } from './lib/utils';

const samplingRate = isProduction() ? 0.1 : 1.0;

Sentry.init({
	enabled: !isDevelopment(),
	dsn: 'https://b85a84f993f6e8f3ec2f46ad96efeee5@o1120245.ingest.sentry.io/4505801482960896',
	tracesSampleRate: samplingRate,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: samplingRate,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: samplingRate,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [Sentry.replayIntegration()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = console.error;
