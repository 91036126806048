import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [];

export const dictionary = {
		"/(content)": [6,[3]],
		"/(content)/ai": [7,[3]],
		"/(content)/arts": [8,[3]],
		"/(content)/auction-houses": [9,[3]],
		"/(content)/config": [10,[3]],
		"/(content)/crawler": [11,[3]],
		"/(content)/exhibitions": [12,[3]],
		"/(auth)/forbidden": [4,[2]],
		"/(auth)/login": [5,[2]],
		"/(content)/users": [13,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';